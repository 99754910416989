<template>
  <div class="h100">
    <el-card class="box-card">
      <el-form :inline="true" :model="search" class="demo-form-inline">
  <el-form-item label="方法名">
    <el-input v-model="search.name" placeholder="请输入方法名"></el-input>
  </el-form-item>
  <el-form-item label="所属模块" prop="moduleId">
    <el-select v-model="search.moduleId" clearable placeholder="请选择模块功能">
      <template v-for="(item,i) in modules">
<el-option :value="item.id" :label="item.module" :key="i"></el-option>
      </template>
    </el-select>
  </el-form-item>
  <el-form-item>
    <el-button type="primary" @click="getList">查询</el-button>
  </el-form-item>
  <el-form-item>
    <el-button type="primary" @click="dialogVisible = true;form={};type='新增'">新增</el-button>
  </el-form-item>
</el-form>
      <el-row class="mb20">
        
      </el-row>
      <el-table :data="tableData" border style="width: 100%" size="mini">
        <el-table-column prop="moduleName" label="所属模型">
        </el-table-column>
        <el-table-column prop="name" label="方法名">
        </el-table-column>
        <el-table-column  label="方法体">
          <template slot-scope="scope">
<el-button @click="handleLook(scope.row)" type="text" size="small"
              >查看</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="enableRun"
          label="无参运行"
          :formatter="formatNum"
          width="50"
        >
        </el-table-column>
         <el-table-column prop="remark" label="说明">
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" > </el-table-column>
        <el-table-column prop="updateTime" label="更新时间"> </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button @click="handleEdit(scope.row)" type="text" size="small"
              >编辑</el-button
            >
            <el-popconfirm
              title="这是一段内容确定删除吗？"
              @confirm="handleDel(scope.row)"
            >
              <el-button slot="reference" type="text" class="ml10" size="small"
                >删除</el-button
              >
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <!-- <div class="tablePage"> -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.currentPage"
        :page-sizes="[10, 200, 300, 400]"
        :page-size="page.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total"
      >
      </el-pagination>
      <!-- </div> -->
    </el-card>
    <el-dialog :title="type" :visible.sync="dialogVisible" width="80%">
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-form-item label="所属模块" prop="moduleId">
    <el-select v-model="form.moduleId" placeholder="请选择模块功能">
      <template v-for="(item,i) in modules">
<el-option :value="item.id" :label="item.module" :key="i"></el-option>

      </template>
      
    </el-select>
  </el-form-item>
        <el-form-item label="解析方法名" prop="name">
          <el-input v-model="form.name" placeholder="请输入方法名称"></el-input>
        </el-form-item>
        <el-form-item label="方法体" prop="body">
          <el-input v-model="form.body" rows="10" type="textarea" placeholder="请输入方法体"></el-input>
        </el-form-item>
        <el-form-item label="无参运行" prop="enableRun">
          <el-switch
            v-model="form.enableRun"
            active-text="是"
            inactive-text="否"
          ></el-switch>
        </el-form-item>
        <el-form-item label="方法描述说明" prop="body">
          <el-input v-model="form.remark" rows="3" type="textarea" placeholder="请输入方法描述说明"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('form')">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
  title="查看"
  :visible.sync="methodShow"
  @close="codeClode"
  width="50%">
  <el-input type="textarea" :readonly="true" rows="15" v-model="methodBody"></el-input>
  <span slot="footer" class="dialog-footer">
    <el-button @click="methodShow = false">取 消</el-button>
    <el-button type="primary" @click="methodShow = false">确 定</el-button>
  </span>
</el-dialog>
  </div>
</template>

<script>
import { methodList, delMethod, methodAddOrUp,moduleList } from "@/api/casecondit";
export default {
  data() {
    let asicc = (rule, value, callback) => {
      if (/^[a-z|A-Z]+$/.test(value)) {
        callback();
      } else {
        callback(new Error("请输入a-z或A-Z的字母"));
      }
    };
    return {
      search:{},
      modules:[],
      methodShow:false,
      methodBody:"",
      type: "新增",
      form: {},
      rules: {
        name: [
          { required: true, message: "请输入方法名", trigger: "blur" },
          { validator: asicc, trigger: "blur" },
        ],
        moduleId:[
          { required: true, message: "请选择模块名", trigger: "blur" }
        ],
        code: [{ required: true, message: "请输入模型编码", trigger: "blur" }],
      },
      page: {
        total: 0,
        currentPage: 1,
        page: 1,
        size: 10,
      },
      dialogVisible: false,
      tableData: [],
    };
  },
  mounted() {
    this.getList();
    moduleList({size:1000}).then(res=>{
      this.modules = res.data.records;
    })
  },
  methods: {
    codeClode(){
    },
    handleLook(row) {
      this.methodBody = row.body;
      this.methodShow = true;
    },
    formatNum(row, column, cellValue, index) {
      return cellValue == 1 ? "是" : "否";
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.form.enableRun){
            this.form.enableRun = 1;
          }else{
            this.form.enableRun = 0;
          }
          methodAddOrUp(this.form)
            .then((res) => {
              if (res.code == 200) {
                this.dialogVisible = false;
                this.form = {};
                this.getList();
              }
              this.type = "新增";
            })
            .catch((e) => {});
          // alert('submit!');
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleEdit(row) {
      this.type = "编辑";
      this.form = row;
      if (row.enableRun == 1) {
        this.form.enableRun = true;
      } else {
        this.form.enableRun = false;
      }
      this.dialogVisible = true;
    },
    handleDel(row) {
      delMethod(row.id).then((res) => {
        this.getList();
      });
    },
    getList() {
      let data= Object.assign(this.page,this.search);
      methodList(data).then((res) => {
        this.tableData = res.data.records;
        this.page.total = res.data.total;
        this.page.currentPage = res.data.current;
      });
    },
    handleSizeChange(val) {
      this.page.size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page.page = val;
      this.getList();
    },
  },
};
</script>

<style>
</style>